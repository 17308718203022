
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Button from 'library/atoms/Button';
import EolaLayout from 'library/atoms/EolaLayout';
import HeadContainer from 'library/atoms/HeadContainer';
import useTranslation from 'next-translate/useTranslation';
import HeroIllustration from './HeroIllustration';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import Input, { InputCalendar } from 'library/atoms/Input';
import Icon from 'shared/library/atoms/Icon';
import { H1, H2, Text, H5 } from 'library/atoms/Typography';
import { generateCloudinaryUrl } from 'shared/helpers/generateCloudinaryUrl';
import { useRouter } from 'next/router';
import MarketplaceModals, { ModalProps, MODAL_CONFIG, MODAL_IDS } from './MarketplaceModals';
import useModal from 'shared/state/useModal';
import Carousel, { CarouselSlide, CarouselSlot, CarouselNavigation } from 'library/molecules/Carousel';
import { Schedulables_With_Distance } from 'shared/presenters/graphqlTypes';
import { OutletPresenter, SchedulablePresenter } from 'presenters';
import translateChargeableTitle from 'helpers/translateChargeableTitle';
import CoverImage from 'library/atoms/CoverImage';
import Item, { ItemTextCta } from 'library/molecules/Item';
import Link from 'next/link';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Query_Root } from 'shared/presenters/graphqlTypes';
import { ON_SERVER } from 'shared/enums/environment';
import { SchedulableTypeEnum } from 'shared/enums/schedulables';
import widgetRoutes, { EOLA_ROUTES } from 'enums/routes';
import CarouselSlideSkeleton, { SubtitleSlideSkeleton } from './EolaMarketplace.skeleton';
import { defined, definedNotNull } from 'shared/presenters/presenter';
import { SOURCE_SUFFIX, LONDON } from 'enums/marketplace';
import { InputPlaces } from 'library/atoms/Input';
import LocationTag from 'library/molecules/LocationTag';
import determineSessionLocationURL from 'shared/helpers/determineSessionLocationURL';
interface Card {
    href: string;
    title: string;
    image: string;
    address?: string;
}
interface CarouselType {
    title: string;
    subtitle: string;
    schedulables?: Card[];
}
type FetchSchedulablesVars = {
    // TODO #5411 - Add var "ipAddress". Waiting for BE to allow us to fetch data based on the user's IP address.
    locale: string;
    schedulableType: 'Activity' | 'Rental';
    latitude: number;
    longitude: number;
};
interface FindExperiencesForm {
    calendar: string;
    search: string;
    location: string;
}
const FORM_INITIAL_VALUES: FindExperiencesForm = {
    calendar: '',
    search: '',
    location: ''
};
const EolaMarketplace = () => {
    const { push } = useRouter();
    const { t } = useTranslation('marketplace');
    const [searchLatitude, setSearchLatitude] = useState<null | number>(null);
    const [searchLongitude, setSearchLongitude] = useState<null | number>(null);
    const [nearbyLatitude, setNearbyLatitude] = useState<null | number>(null);
    const [nearbyLongitude, setNearbyLongitude] = useState<null | number>(null);
    useEffect(() => {
        fetch(determineSessionLocationURL())
            .then(response => response.json())
            .then(data => {
            setNearbyLatitude(data.latitude);
            setNearbyLongitude(data.longitude);
        })
            .catch(error => console.error('Error:', error));
    }, []);
    const lat = nearbyLatitude || LONDON.latitude;
    const lng = nearbyLongitude || LONDON.longitude;
    const activities = useFetchData('Activity', lat, lng);
    const rentals = useFetchData('Rental', lat, lng);
    const { activeModal, setActiveModalId, currentModalProps } = useModal<ModalProps>({
        MODAL_CONFIG,
        modalPropVariations: {
            calendar: {
                name: 'calendar',
                onDateChange: () => setActiveModalId(null)
            }
        }
    });
    const RECORD_SEARCH = gql `
    mutation record_a_search_was_made($query: String!) {
      Consumer_Search_Record(query: $query) {
        success
      }
    }
  `;
    const [recordSearch] = useMutation(RECORD_SEARCH);
    const onSubmit = ({ calendar, search, location }: FindExperiencesForm) => {
        const searchQuery = search ? { q: search } : {};
        const dateQuery = calendar
            ? { date: dayjs(calendar).format('YYYY-MM-DD') }
            : {};
        const searchLocation = location ? { location: location } : {};
        const latQuery = searchLatitude ? { lat: searchLatitude } : {};
        const lngQuery = searchLongitude ? { lng: searchLongitude } : {};
        recordSearch({
            variables: { query: search }
        });
        push({
            pathname: EOLA_ROUTES.SEARCH.url(),
            query: {
                ...searchQuery,
                ...dateQuery,
                ...latQuery,
                ...lngQuery,
                ...searchLocation
            }
        });
    };
    return (<>
      <HeadContainer title={t('title')} description={t('description')} keywords={t('keywords')} image={generateCloudinaryUrl('image/upload/v1653565476/eola/public_images/eola-meta-tag-og-image.png')}/>
      <Formik initialValues={FORM_INITIAL_VALUES} onSubmit={onSubmit}>
        {({ handleSubmit }) => (<>
            <EolaLayout>
              <StyledHero>
                <StyledForm>
                  <H1 py={6}>{t('heading')}</H1>
                  <InputCalendar name="calendar" placeholder={t('when-going')} calendarModalKey={MODAL_IDS.calendar}/>
                  <InputPlaces name="location" placeholder={t('where-going')} handleSelect={(lat, lng) => {
                setSearchLatitude(lat);
                setSearchLongitude(lng);
            }}/>
                  <Input name="search" variant="large" icon="mag-glass" placeholder={t('what-doing')} onSubmitKeysDown={handleSubmit}/>
                  <Button type="button" variant="eola-primary" onClick={handleSubmit}>
                    {t('common:search')}
                    <Icon name="arrow-right" ml={3} color="currentColor" size={13}/>
                  </Button>
                </StyledForm>
                <HeroIllustration />
              </StyledHero>

              <CarouselSection title={t('activities-near-you')} subtitle={t('x-activities-near', {
                count: activities?.length ?? 0
            })} schedulables={activities}/>

              <CarouselSection title={t('rentals-near-you')} subtitle={t('x-rentals-near', {
                count: rentals?.length ?? 0
            })} schedulables={rentals}/>
            </EolaLayout>
            <MarketplaceModals setActiveModalId={setActiveModalId} activeModal={activeModal} {...currentModalProps}/>
          </>)}
      </Formik>
    </>);
};
export default EolaMarketplace;
const CarouselSection = ({ title, subtitle, schedulables }: CarouselType) => {
    const { t } = useTranslation();
    return (<StyledSection>
      <StyledOneColumnWrapper>
        <StyledCarousel>
          <StyledCarouselHeader slot="container-start">
            <div>
              <H2>{title}</H2>
              {schedulables ? (<Text fontStyle="p2" color="secondary">
                  {subtitle}
                </Text>) : (<SubtitleSlideSkeleton />)}
            </div>
            <CarouselNavigation />
          </StyledCarouselHeader>
          {schedulables
            ? schedulables.map(card => (<CarouselSlide key={card.title}>
                  <Link href={card.href} passHref legacyBehavior>
                    <StyledCard variant="card" title={t('common:view-item-name', {
                    itemName: translateChargeableTitle(card.title, t)
                })} target="_blank">
                      <CoverImage src={card.image}/>
                      <ItemTextCta>
                        <StyledCardTitle>
                          {translateChargeableTitle(card.title, t)}
                        </StyledCardTitle>
                      </ItemTextCta>
                      <LocationTag text={card.address}/>
                    </StyledCard>
                  </Link>
                </CarouselSlide>))
            : /**
               * Load the skeleton if the data is not ready yet.
               */
                Array.from(Array(20).keys(), n => n + 1).map(card => (<CarouselSlide key={card}>
                  <CarouselSlideSkeleton />
                </CarouselSlide>))}
        </StyledCarousel>
      </StyledOneColumnWrapper>
    </StyledSection>);
};
/**
 * TODO #5411 - Fetch this data based on geolocation.
 *
 * The main goal is to use geolocation to fetch this data but we are waiting for the BE to provide
 * a solution for this #5411. We are sure we can't get the geolocation on getServerSideProps or
 * getStaticProps so it makes sense to keep this as client-side fetch since the BE will provide the
 * geolocation fetch soon and it will demand less refactorin if we keep it as client-side fetch.
 */
const useFetchData = (schedulableType: FetchSchedulablesVars['schedulableType'], lat: number, lng: number) => {
    const { locale } = useRouter();
    const { data } = useQuery<Pick<Query_Root, 'location_search_schedulables'>, FetchSchedulablesVars>(FETCH_SCHEDULABLES, {
        variables: {
            schedulableType,
            locale: locale as string,
            latitude: lat,
            longitude: lng
        },
        skip: ON_SERVER,
        ssr: ON_SERVER
    });
    const formatSchedulables = (schedulablesJSON?: Schedulables_With_Distance[]) => {
        if (!schedulablesJSON)
            return;
        const cards: Card[] = schedulablesJSON.map(schedulableJSON => {
            const schedulable = new SchedulablePresenter(definedNotNull(schedulableJSON.schedulable));
            const outlet = new OutletPresenter(definedNotNull(schedulableJSON.schedulable).outlet);
            const isActivity = schedulable.type == SchedulableTypeEnum.Activity;
            const widgetSlug = defined(outlet?.widgets?.[0].slug);
            const schedulableSlug = defined(schedulable.slug);
            const schedulableHref = isActivity
                ? widgetRoutes.ACTIVITY.url({
                    widgetSlug,
                    activitySlug: schedulableSlug
                })
                : widgetRoutes.RENTAL.url({ widgetSlug, rentalSlug: schedulableSlug });
            return {
                href: schedulableHref + '?' + SOURCE_SUFFIX,
                title: schedulable.name(),
                image: schedulable.imageURL(),
                address: schedulable.addressSummaryLine()
            };
        });
        return cards;
    };
    return formatSchedulables(data?.location_search_schedulables);
};
const FETCH_SCHEDULABLES = gql `
  query WidgetEolaMarketplaceFetchSchedulables(
    $schedulableType: String!
    $locale: String!
    $latitude: float8!
    $longitude: float8!
  ) {
    location_search_schedulables(
      args: { lat: $latitude, long: $longitude }
      order_by: { distance: asc }
      limit: 50
      where: {
        schedulable: {
          hide_from_marketplace: { _eq: false }
          type: { _eq: $schedulableType }
          materialized_bookability_by_days: { hide_from_public: { _eq: false } }
          outlet: {
            admin_forced_marketplace_disable: { _eq: false }
            feature_toggles: { marketplace_enabled: { _eq: true } }
            widgets: { primary: { _eq: true } }
          }
          address: {
            city: { _is_null: false }
            country: { _is_null: false }
            latitude: { _is_null: false, _neq: "0" }
            longitude: { _is_null: false, _neq: "0" }
          }
        }
      }
    ) {
      schedulable {
        id
        type
        slug
        image
        translations(
          where: {
            language: { _in: [$locale, "en"] }
            field: { _in: ["name", "tagline"] }
            model_type: { _eq: "Schedulable" }
          }
        ) {
          language
          field
          value_scrubbed
        }
        default_image {
          id
          image
        }
        address {
          id
          country
          city
        }
        outlet {
          widgets(where: { primary: { _eq: true } }) {
            slug
          }
        }
      }
    }
  }
`;
const StyledHero = styled.main(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: 0,
    paddingBottom: theme.space[8],
    textAlign: 'center',
    [theme.mediaQueries.md]: {
        borderRadius: theme.radii[3],
        paddingBottom: 0,
        minHeight: '80vh'
    }
}));
const StyledForm = styled(Form)(({ theme }) => ({
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[5],
    padding: theme.space[9],
    ['[type="button"]']: {
        alignSelf: 'center'
    }
}));
const StyledSection = styled.section(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
}));
const StyledOneColumnWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[7],
    padding: theme.mixins.pxSpread([theme.space[6], theme.space[5]]),
    ...theme.mixins.centeredContainer('xxxl')
}));
const StyledCarousel = styled(Carousel)(({ theme }) => ({
    // Create space for the shadow that gets cut off because of the overflow hidden
    ...theme.mixins.createPseudoSpace({
        position: 'bottom',
        size: 8
    }),
    '.swiper-slide': {
        width: 320
    }
}));
const StyledCarouselHeader = styled(CarouselSlot)(({ theme }) => ({
    display: 'flex',
    flex: '1 0 0',
    justifyContent: 'space-between',
    marginBottom: theme.space[5]
}));
const StyledCard = styled(Item)(({ theme }) => ({
    margin: theme.mixins.pxSpread([0, theme.space[1]])
}));
const StyledCardTitle = styled(H5)(({ theme }) => ({
    ...theme.mixins.ellipsisWidthClamp(250)
}));

    async function __Next_Translate__getStaticProps__194f56e38d6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'library//EolaMarketplace/EolaMarketplace',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194f56e38d6__ as getStaticProps }
  