
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import styled from '@emotion/styled';
import svgPathActivities from 'assets/icons/iconsToBeImportedWithImg/marketplace-hero-activities.svg';
import svgPathAirplane from 'assets/icons/iconsToBeImportedWithImg/marketplace-hero-airplane.svg';
import svgPathBallon from 'assets/icons/iconsToBeImportedWithImg/marketplace-hero-ballon.svg';
import svgPathCloudLeft from 'assets/icons/iconsToBeImportedWithImg/marketplace-hero-cloud-left.svg';
import svgPathCloudRight from 'assets/icons/iconsToBeImportedWithImg/marketplace-hero-cloud-right.svg';
import svgPathMountain from 'assets/icons/iconsToBeImportedWithImg/marketplace-hero-mountain.svg';
import useTranslation from 'next-translate/useTranslation';
const HeroIllustration = () => {
    const { t } = useTranslation('marketplace');
    return (<StyledHeroIllustration>
      <StyledCloudLeft height={148} width={336} src={svgPathCloudLeft as string} alt={t('cloud')}/>
      <StyledCloudRight height={225} width={379} src={svgPathCloudRight as string} alt={t('cloud')}/>
      <StyledAirplane height={57} width={50} src={svgPathAirplane as string} alt={t('airplane')}/>
      <StyledBallon height={288} width={239} src={svgPathBallon as string} alt={t('ballon')}/>
      <StyledActivities height={174} width={1600} src={svgPathActivities as string} alt={t('common:activities')}/>
      <StyledMountain height={278} width={1600} src={svgPathMountain as string} alt={t('mountain')}/>
    </StyledHeroIllustration>);
};
export default HeroIllustration;
const StyledHeroIllustration = styled.div(() => {
    // // TODO - move to Eola variables if used more regularly
    const customColor = {
        yellow: '#FFD803',
        green: '#00EBC7',
        pink: '#FF5470'
    };
    return {
        position: 'absolute' as const,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: 'none' as const,
        background: customColor.yellow,
        animation: `changeBgColor 120s infinite linear`,
        '@keyframes changeBgColor': {
            '0%': { background: customColor.yellow },
            '33%': { background: customColor.green },
            '66%': { background: customColor.pink },
            '99%': { background: customColor.yellow }
        }
    };
});
const StyledCloudLeft = styled.img(() => ({
    position: 'absolute' as const,
    top: '-6%',
    left: -160,
    width: '100%',
    maxWidth: 490,
    height: 'auto'
}));
const StyledCloudRight = styled.img(({ theme }) => ({
    display: 'none',
    position: 'absolute',
    top: '5%',
    right: -170,
    width: '100%',
    maxWidth: 535,
    height: 'auto',
    [theme.mediaQueries.md]: {
        display: 'block'
    }
}));
const StyledAirplane = styled.img(({ theme }) => ({
    position: 'absolute',
    top: '15vh',
    left: '-1%',
    transform: 'scale(.5)',
    transformOrigin: 'left',
    width: '100%',
    maxWidth: 70,
    height: 'auto',
    opacity: 0.4,
    [theme.mediaQueries.md]: {
        top: '50vh',
        left: '-1%',
        transform: 'scale(1)'
    }
}));
const StyledBallon = styled.img(({ theme }) => ({
    position: 'absolute',
    top: '10vh',
    right: '-10%',
    width: '100%',
    transform: 'scale(.5)',
    transformOrigin: 'right',
    maxWidth: 240,
    height: 'auto',
    opacity: 0.4,
    [theme.mediaQueries.md]: {
        top: '25vh',
        right: '5%',
        transform: 'scale(1)'
    }
}));
const StyledMountain = styled.img(() => ({
    position: 'absolute' as const,
    bottom: -10,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '120%',
    height: 'auto',
    opacity: 0.4
}));
const StyledActivities = styled.img(() => ({
    position: 'absolute' as const,
    bottom: -10,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '120%',
    height: 'auto'
}));

    async function __Next_Translate__getStaticProps__194f56e3e86__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'library//EolaMarketplace/HeroIllustration',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194f56e3e86__ as getStaticProps }
  